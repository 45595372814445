import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import {
  ACCOUNT_CREDIT_AMOUNT_UPDATED,
  ACCOUNT_INVOICE_CREATED,
  ACCOUNT_INVOICE_DELETED,
  ACCOUNT_INVOICE_FETCHED,
  ACCOUNT_INVOICE_UPDATED,
  ACCOUNT_PAID_UNTIL_UPDATED,
  ACCOUNT_BUSINESS_CATEGORY_UPDATED,
  ACCOUNT_PRICE_UPDATED,
  ACCOUNT_CONTRACT_UPDATED,
  ACCOUNT_PRICE_VUNIT_UPDATED,
  ACCOUNT_SMS_BILLING_UPDATED,
  ACCOUNT_SMS_ALLOWED_UPDATED,
  ACCOUNT_SMS_CAMPAIGN_UPDATED,
  ACCOUNT_SMS_LIMITS_UPDATED,
  ACCOUNT_SMS_UPDATED,
  ACCOUNT_EMAIL_CAMPAIGN_UPDATED,
  ACCOUNT_ACTIVATED_UPDATED,
  ACCOUNT_TERMINATED_UPDATED,
  ACCOUNT_TYPE_UPDATED,
  ACCOUNT_TRIAL_DAYS_UPDATED,
  ACCOUNT_PAYMENT_METHOD_UPDATED,
  ACCOUNT_INVOICE_CUSTOMER_UPDATED,
  LOCATION_HARDWARE_PRICING_ID_CREATED,
  LOCATION_HARDWARE_PRICING_ID_DELETED,
  LOCATION_HARDWARE_PRICING_ID_FETCHED,
  LOCATION_HARDWARE_PRICING_ID_UPDATED,
  LOCATIONS_FETCHED,
  SYSTEM_ADMIN_CONTROL_FILTER_TYPE,
  SYSTEM_ADMIN_CONTROL_SORT,
  SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR,
  SYSTEM_ADMIN_ORGANIZATION_ID_FETCHED,
  SYSTEM_ADMIN_ORGANIZATIONS_FETCHED,
  ACCOUNT_FORTNOX_INVOICE_CREATED,
  SYSTEM_ADMIN_CONTACTS_FETCHED,
  PRICE_PLANS_FETCHED,
  ACCOUNT_LOCATION_TERMINATED,
  RELEASE_NOTES_FETCHED,
  RELEASE_NOTE_CREATED,
  RELEASE_NOTE_UPDATED,
  RELEASE_NOTE_DELETED,
  RELEASE_NOTE_IMAGE_UPDATED,
  ACCOUNT_LEAD_SENT,
  ACCOUNT_LOGS_FETCHED,
  ACCOUNT_COMMENT_SAVED,
  ACCOUNT_COMMENT_UPDATED,
  ACCOUNT_COMMENT_DELETED,
  ACCOUNT_PREFS_UPDATED
} from '@State/sysadmin-actions';

export function sysadminInvoice(state = List([]), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR: {
      return fromJS([]);
    }
    case ACCOUNT_INVOICE_FETCHED: {
      return fromJS((action.invoice));
    }
    case ACCOUNT_INVOICE_UPDATED: {
      const invoiceIndex = state.findIndex(item => item.get('uuid') === action.data.invoiceUUID);
      if (invoiceIndex !== -1) {
        const itemInd = state.getIn([invoiceIndex, 'items']).findIndex((item) => item.get('id') === action.data.invoiceItemId);
        return state.setIn([invoiceIndex, 'items', itemInd], fromJS(action.data));
      }
      return state;
    }
    case ACCOUNT_INVOICE_CREATED: {
      const indexInvoice = state.findIndex((g) => g.get('uuid') === action.data.invoiceUUID);
      return state.updateIn([indexInvoice, 'items'], arr => arr.push(fromJS(action.data)));
    }
    case ACCOUNT_INVOICE_DELETED: {
      const invoiceIndex = state.findIndex((g) => g.get('uuid') === action.data.invoiceUUID);
      if (invoiceIndex !== -1) {
        const delInd = state.getIn([invoiceIndex, 'items']).findIndex((item) => item.get('id') === action.data.invoiceItemId);
        return state.deleteIn([invoiceIndex, 'items', delInd]);
      }
      return state;
    }
    case ACCOUNT_FORTNOX_INVOICE_CREATED: {
      return state.withMutations(map => {
        action.periodKeys.forEach(periodKey => {
          const invoiceIndex = map.findIndex(i => i.get('periodKey') === periodKey);
          map.setIn([invoiceIndex, 'fortnoxInvoiceId'], action.fortnoxInvoiceId);
        });
      });
    }
    default:
      return state;
  }
}

export function sysadminLocationsById(state = List(), action = null) {
  switch (action.type) {
    case LOCATIONS_FETCHED: {
      return fromJS(action.data);
    }
    case ACCOUNT_LOCATION_TERMINATED: {
      const { orgCtxName, ctxName } = action;
      const index = state.findIndex(s => {
        return s.get('orgCtxName') == orgCtxName && s.get('ctxName') === ctxName;
      });
      return index !== -1 ? state.delete(index) : state;
    }
    default:
      return state;
  }
}

export function sysadminPricePlansById(state = Map({}), action = null) {
  switch (action.type) {
    case PRICE_PLANS_FETCHED: {
      return fromJS(action.data);
    }
    default:
      return state;
  }
}

export function sysadminOrganizationById(state = Map({}), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR: {
      return fromJS({});
    }
    case ACCOUNT_TRIAL_DAYS_UPDATED: {
      return state.setIn(['trialDays'], action?.data?.trialDays);
    }
    case ACCOUNT_CREDIT_AMOUNT_UPDATED: {
      return state.setIn(['creditAmount'], action?.data?.amount);
    }
    case ACCOUNT_BUSINESS_CATEGORY_UPDATED: {
      return state.setIn(['businessCategory'], action.data.category);
    }
    case ACCOUNT_PAID_UNTIL_UPDATED: {
      return state.setIn(['paidUntil'], action.data.paidUntil);
    }
    case ACCOUNT_PRICE_UPDATED:
    case ACCOUNT_CONTRACT_UPDATED:
    case ACCOUNT_PRICE_VUNIT_UPDATED:
    case ACCOUNT_SMS_UPDATED: {
      return fromJS({
        ...state.toJS(),
        ...action.subscription,
        subscriptionPricing: {
          ...state.get('subscriptionPricing')?.toJS(),
          ...action.pricing
        }
      });
    }
    case SYSTEM_ADMIN_ORGANIZATION_ID_FETCHED: {
      return fromJS(action.subscription)
        .set('invoiceCustomer', fromJS(action.invoiceCustomer))
        .set('signup', fromJS(action.signup));
    }
    case SYSTEM_ADMIN_CONTACTS_FETCHED: {
      return state.set('contacts', fromJS(action.contacts));
    }
    case ACCOUNT_INVOICE_CUSTOMER_UPDATED: {
      return state.set('invoiceCustomer', fromJS(action.invoiceCustomer));
    }
    case ACCOUNT_PAYMENT_METHOD_UPDATED: {
      return state.set('paymentMethod', action.paymentMethod);
    }
    case ACCOUNT_ACTIVATED_UPDATED: {
      return state.set('trial', null)
        .set('activationTime', moment().format('L'));
    }
    case ACCOUNT_TERMINATED_UPDATED: {
      return state.set('terminated', action.when === 'Now')
        .set('terminateOn', action.when === 'EndOfMonth'
          ? moment().add(1, 'month').startOf('month')
          : null);
    }
    case ACCOUNT_TYPE_UPDATED: {
      return state.set('orgAccountType', action.data?.accountType);
    }
    case ACCOUNT_SMS_BILLING_UPDATED: {
      return state.set('smsBillingEnabled', action.data);
    }
    case ACCOUNT_SMS_ALLOWED_UPDATED: {
      return state.set('allowSms', action.data);
    }
    case ACCOUNT_SMS_CAMPAIGN_UPDATED: {
      return state.set('allowSmsCampaign', action.data);
    }
    case ACCOUNT_SMS_LIMITS_UPDATED: {
      const { sendLimitBulk, sendLimitIndividual } = action.limits;
      return state.set('sendLimitBulk', sendLimitBulk)
        .set('sendLimitIndividual', sendLimitIndividual);
    }
    case ACCOUNT_EMAIL_CAMPAIGN_UPDATED: {
      return state.set('allowEmailCampaign', action.data);
    }
    case ACCOUNT_LEAD_SENT: {
      return state.setIn(['signup', 'sfLeadCreatedTs'], moment().toISOString());
    }
    case ACCOUNT_PREFS_UPDATED: {
      return state.set('prefs', state.get('prefs').merge(action.prefs));
    }
    default:
      return state;
  }
}

export function sysadminControl(state = Map({ sort: false, filterType: '' }), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_CONTROL_SORT: {
      return state.set('sort', action.value);
    }
    case SYSTEM_ADMIN_CONTROL_FILTER_TYPE: {
      return state.set('filterType', action.value);
    }
    default:
      return state;
  }
}

export function sysadminOrganizationsState(state = List([]), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_ORGANIZATIONS_FETCHED: {
      return fromJS(action.organizations);
    }
    case ACCOUNT_TRIAL_DAYS_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === +action?.data?.orgId);
      if (index !== -1) {
        const isPastDate = moment().isBefore(moment(state.getIn([index]).get('trialStartTs')).add('days', action?.data?.trialDays));

        return state
          .setIn([index, 'trial'], isPastDate)
          .setIn([index, 'trialDays'], action?.data?.trialDays);
      }
      return state;
    }
    case ACCOUNT_TYPE_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === +action?.data?.orgId);
      if (index !== -1) {
        const isPastDate = moment().isBefore(moment(state.getIn([index]).get('trialStartTs')).add('days', action?.data?.trialDays));
        return state
          .setIn([index, 'orgAccountType'], action.data?.accountType)
          .setIn([index, 'trial'], action.data?.accountType === 'Test' ? false : isPastDate);
      }
      return state;
    }
    case ACCOUNT_PAID_UNTIL_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === +action?.data?.orgId);
      const isPastDate = moment().isAfter(action?.data?.paidUntil);
      return state
        .setIn([index, 'paidUntilTs'], action?.data?.paidUntil)
        .setIn([index, 'unpaid'], isPastDate);
    }
    case ACCOUNT_ACTIVATED_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === +action?.data?.orgId);
      return state.setIn([index, 'trial'], false);
    }
    case ACCOUNT_LEAD_SENT: {
      const index = state.findIndex((item) => item.get('id') === +action.orgId);
      return state.setIn([index, 'sfLeadCreatedTs'], moment().toISOString());
    }
    case ACCOUNT_COMMENT_SAVED: {
      const index = state.findIndex((item) => item.get('id') === +action.orgId);
      return state.setIn([index, 'logCount'], state.getIn([index, 'logCount']) + 1);
    }
    case ACCOUNT_CONTRACT_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === +action.orgId);
      const { contractStartDate, contractEndDate } = action.subscription;
      return state.setIn([index, 'contractStartDate'], contractStartDate)
        .setIn([index, 'contractEndDate'], contractEndDate);
    }
    default:
      return state;
  }
}

export function sysadminLocationHardwarePricing(state = List([]), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR: {
      return fromJS([]);
    }
    case LOCATION_HARDWARE_PRICING_ID_DELETED: {
      const index = state.findIndex((item) => item.get('id') === action?.data?.itemTemplateId);
      return state.delete(index);
    }
    case LOCATION_HARDWARE_PRICING_ID_FETCHED: {
      return fromJS(action.locationHardwarePricing);
    }
    case LOCATION_HARDWARE_PRICING_ID_CREATED: {
      return state.push(fromJS(action.data));
    }
    case LOCATION_HARDWARE_PRICING_ID_UPDATED: {
      const index = state.findIndex(item => item.get('id') === action?.data?.id);
      if (index !== -1) {
        return state.setIn([index], fromJS(action.data));
      }
      return state;
    }
    default:
      return state;
  }
}

export function sysadminReleaseNotes(state = List(), action = null) {
  switch (action.type) {
    case RELEASE_NOTES_FETCHED: {
      return fromJS(action.releaseNotes);
    }
    case RELEASE_NOTE_CREATED: {
      return state.push(fromJS(action.releaseNote));
    }
    case RELEASE_NOTE_UPDATED: {
      const index = state.findIndex(item => item.get('id') === parseInt(action.releaseNote.id));
      return index !== -1
        ? state.setIn([index], fromJS(action.releaseNote))
        : state;
    }
    case RELEASE_NOTE_DELETED: {
      const index = state.findIndex(item => item.get('id') === parseInt(action.id));
      if (index !== -1) {
        return state.delete(index);
      }
      return state;
    }
    case RELEASE_NOTE_IMAGE_UPDATED: {
      const index = state.findIndex(item => item.get('id') === parseInt(action.id));
      return index !== -1
        ? state.setIn([index, 'imageUrl'], action.imageUrl)
        : state;
    }
    default:
      return state;
  }
}

export function sysadminLogEntries(state = Map({ logs: fromJS([]), loading: true }), action = null) {
  switch (action.type) {
    case SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR:
      return state.set('logs', fromJS([])).set('loading', true);

    case ACCOUNT_LOGS_FETCHED: {
      return state.set('logs', fromJS(action.logentries)).set('loading', false);
    }

    case ACCOUNT_COMMENT_SAVED: {
      return state.setIn(['logs'], state.get('logs').insert(0, fromJS(action.result)));
    }

    case ACCOUNT_COMMENT_UPDATED: {
      const comments = state.get('logs').toJS();
      const index = comments.findIndex((c) => c.id === action.commentId);
      if (index !== -1) {
        const { comment } = action;
        const newComment = {
          ...comments[index], lastUpdate: moment(), comment
        };
        return state.setIn(['logs', index], newComment);
      }
      return state;
    }

    case ACCOUNT_COMMENT_DELETED: {
      const comments = state.get('logs').toJS();
      const index = comments.findIndex((c) => c.id === action.commentId);
      return state.deleteIn(['logs', index]);
    }

    default:
      return state;
  }
}
